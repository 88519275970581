import { atom, atomFamily, selector, selectorFamily } from "recoil";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { userUriState } from "@states/auth.state";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import { getRequestState, useRefreshState } from "./request.state";
import {
  customerSupplierAccreditationState,
  customerSupplierState
} from "./settings.state";

export const isChangePasswordState = atom({
  key: "isChangePassword",
  default: false
});

export const customJobStatusState = atom({
  key: "CustomJobStatus",
  default: ""
});

export const countryCodeState = atom({
  key: "CountryCode",
  default: ""
});

export const profileState = selectorFamily({
  key: "profile",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("profile", userUri));

      if (!userUri) return undefined;

      const profile = await smavestoCore.services.user.getProfile(userUri);

      return successOrUndefined(profile);
    }
});

export const consultantDataState = selectorFamily({
  key: "consultantDataState",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      if (!userUri) return undefined;

      return successOrUndefined(
        smavestoCore.services.consultant.getConsultantData(userUri)
      );
    }
});

export const consultantImageState = selectorFamily({
  key: "consultantImageState",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      if (!userUri) return undefined;

      return successOrUndefined(
        smavestoCore.services.consultant.getConsultantImage(userUri)
      );
    }
});

// Ticket Reference: WEB-1313
export const displayIndividualConsultantForCurrentDepotOwnerState =
  selectorFamily({
    key: "displayIndividualConsultantForCurrentDepotOwnerState",
    get:
      () =>
      async ({ get }) => {
        const supplier = get(customerSupplierState("currentDepotOwner"));

        if (!supplier) return false;

        const accreditationState = get(
          customerSupplierAccreditationState("currentDepotOwner")
        );

        const consultant = get(consultantDataState("currentDepotOwner"));

        if (!consultant) return false;

        return accreditationState?.some(a => a.status === "RECENT_APPROVED");
      }
  });

export const profileStateForUserUri = selectorFamily({
  key: "profileForUserUri",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("profile", userUri));

      const profile = await smavestoCore.services.user.getProfile(userUri);

      return successOrUndefined(profile);
    }
});

export const useRefreshProfile = (type: StateEnvironmentUser) =>
  useRefreshState("profile", type);

export const currentMobileNumberState = atomFamily({
  key: "currentMobileNumberState",
  default: (type: StateEnvironmentUser) =>
    selector({
      key: "currentMobileNumber/Default",
      get: ({ get }) => {
        const profile = get(profileState(type));

        return profile?.mobile;
      }
    })
});

export const currentMobileNumberForUserUriState = atomFamily({
  key: "currentMobileNumberForUserUriState",
  default: (userUri: string) =>
    selector({
      key: "currentMobileNumber/Default",
      get: ({ get }) => {
        const profile = get(profileStateForUserUri(userUri));

        return profile?.mobile;
      }
    })
});

export const isValidMobileNumberFromDBState = selectorFamily({
  key: "isValidMobileNumberFromDBState",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }): Promise<boolean> => {
      const profile = get(profileState(type));

      if (!profile || !profile.mobile) return false;

      // I dont know why we replace + with %252B but encodeUriComponent does not work with DSER
      // TODO: fix this at DSER
      const phoneValidatorResult =
        await smavestoCore.services.validation.validate(
          "validate-phonenumber",
          `phonenumber=${profile.mobile.replace("+", "%252B")}`
        );

      if (isApiErrorResponse(phoneValidatorResult)) {
        return false;
      }

      return true;
    }
});

export const isValidPhoneNumberFromDBState = selectorFamily({
  key: "isValidPhoneNumberFromDBState",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }): Promise<boolean> => {
      const profile = get(profileState(type));

      if (!profile) return false;

      if (!profile.phone) return true;

      // I dont know why we replace + with %252B but encodeUriComponent does not work with DSER
      // TODO: fix this at DSER
      const phoneValidatorResult =
        await smavestoCore.services.validation.validate(
          "validate-phonenumber",
          `phonenumber=${profile.phone.replace("+", "%252B")}`
        );

      if (isApiErrorResponse(phoneValidatorResult)) {
        return false;
      }

      return true;
    }
});

export const isValidMobileNumberFromDBStateForUserUri = selectorFamily({
  key: "isValidMobileNumberFromDBStateForUserUri",
  get:
    (userUri: string) =>
    async ({ get }): Promise<boolean> => {
      const profile = get(profileStateForUserUri(userUri));

      if (!profile || !profile.mobile) return false;

      // I dont know why we replace + with %252B but encodeUriComponent does not work with DSER
      // TODO: fix this at DSER
      const phoneValidatorResult =
        await smavestoCore.services.validation.validate(
          "validate-phonenumber",
          `phonenumber=${profile.mobile.replace("+", "%252B")}`
        );

      if (isApiErrorResponse(phoneValidatorResult)) {
        return false;
      }

      return true;
    }
});

export const isValidPhoneNumberFromDBStateForUserUri = selectorFamily({
  key: "isValidPhoneNumberFromDBStateForUserUri",
  get:
    (userUri: string) =>
    async ({ get }): Promise<boolean> => {
      const profile = get(profileStateForUserUri(userUri));

      if (!profile) return false;

      if (!profile.phone) return true;

      // I dont know why we replace + with %252B but encodeUriComponent does not work with DSER
      // TODO: fix this at DSER
      const phoneValidatorResult =
        await smavestoCore.services.validation.validate(
          "validate-phonenumber",
          `phonenumber=${profile.phone.replace("+", "%252B")}`
        );

      if (isApiErrorResponse(phoneValidatorResult)) {
        return false;
      }

      return true;
    }
});

export const isAccessSMSChannelForTANState = selectorFamily({
  key: "isAccessSMSChannelForTAN",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const profile = get(profileState(type));
      const currentMobileNumber = get(currentMobileNumberState(type));

      if (!profile?.mobile || !currentMobileNumber) return false;

      const isValidMobileNumberFromDB = get(
        isValidMobileNumberFromDBState(type)
      );

      return (
        isValidMobileNumberFromDB && currentMobileNumber === profile?.mobile
      );
    }
});

export const isAccessSMSChannelForTANForUserUriState = selectorFamily({
  key: "isAccessSMSChannelForTANForUserUriState",
  get:
    (userUri: string) =>
    async ({ get }) => {
      const profile = get(profileStateForUserUri(userUri));
      const currentMobileNumber = get(
        currentMobileNumberForUserUriState(userUri)
      );

      if (!profile?.mobile || !currentMobileNumber) return false;

      const isValidMobileNumberFromDB = get(
        isValidMobileNumberFromDBStateForUserUri(userUri)
      );

      return (
        isValidMobileNumberFromDB && currentMobileNumber === profile?.mobile
      );
    }
});

export const tanChannelState = atomFamily({
  key: "tanChannel",
  default: (type: StateEnvironmentUser) =>
    selector({
      key: "tanChannel/Default",
      get: ({ get }) => {
        const isAccessSMSChannelForTAN = get(
          isAccessSMSChannelForTANState(type)
        );

        return isAccessSMSChannelForTAN ? "sms" : "email";
      }
    })
});

export const tanChannelStateForUserUri = atomFamily({
  key: "tanChannelStateForUserUri",
  default: (userUri: string) =>
    selector({
      key: "tanChannel/Default",
      get: ({ get }) => {
        const isAccessSMSChannelForTAN = get(
          isAccessSMSChannelForTANForUserUriState(userUri)
        );

        return isAccessSMSChannelForTAN ? "sms" : "email";
      }
    })
});
