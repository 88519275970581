import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import {
  consultantDataState,
  consultantImageState
} from "@src/state/profile.state";
import { customerSupplierState } from "@src/state/settings.state";
import { bundledLoadingState } from "@src/utils/recoil/bundledLoadingState";
import { Skeleton } from "antd";
import Image from "next/image";
import { useEffect, useState } from "react";
import type { AppTheme } from "smavesto.core";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import BasicInfoBox from "../info-box/BasicInfoBox";

interface AnCeKaConsultantHintProps {
  theme: AppTheme;
}

const AnCeKaConsultantHint: React.FunctionComponent<
  AnCeKaConsultantHintProps
> = ({ theme }) => {
  const [consultantDataLoadingState, consultantDataOrError] = useAsyncSelector(
    consultantDataState("currentDepotOwner")
  );

  const [consultantImageLoadingState, consultantImage] = useAsyncSelector(
    consultantImageState("currentDepotOwner")
  );

  const [customerSupplierLoadingState, customerSupplier] = useAsyncSelector(
    customerSupplierState("currentDepotOwner")
  );

  const isLoading =
    bundledLoadingState([
      consultantDataLoadingState,
      consultantImageLoadingState,
      customerSupplierLoadingState
    ]) === "loading";

  const [consultantImagePath, setConsultantImagePath] = useState<string>();

  const consultantData = successOrUndefined(consultantDataOrError);

  useEffect(() => {
    if (consultantImage && !isApiErrorResponse(consultantImage)) {
      const reader = new FileReader();
      reader.readAsDataURL(consultantImage);

      reader.onloadend = () => {
        const base64data = reader.result as string;
        setConsultantImagePath(base64data);
      };
    }
  }, [consultantImage]);

  if (isLoading) return <Skeleton />;

  return (
    <BasicInfoBox
      noPadding
      icon={null}
      actions={[]}
      title={<u>Ihre persönlichen Ansprechpartner</u>}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          textAlign: "left",
          gap: ".5rem",
          margin: "auto",
          marginBottom: "1rem",
          borderBottom: `1px solid ${theme.palette.primary}`,
          paddingBottom: "1rem",
          maxWidth: "95%"
        }}
      >
        <div style={{ textAlign: "center", marginRight: "1rem" }}>
          <Image
            style={{
              borderRadius: "50%",
              objectFit: "cover"
            }}
            alt="contact"
            className="image"
            src="/assets/Tim_Bergmann_web.jpg"
            width={100}
            height={100}
          />
          <br />
        </div>

        <div style={{ maxWidth: 400, fontSize: "16px" }}>
          Kontaktieren Sie Ihren persönlichen Smavesto-Ansprechpartner Tim
          Bergmann über die nachfolgenden Kontaktdaten:
          <br />
          <br />
          <a style={{ textDecoration: "none" }} href="tel:+494211794519">
            +49 421 179 4519
          </a>
          <br />
          <a
            style={{ textDecoration: "none" }}
            href="mailto:tim.bergmann@smavesto.de"
          >
            tim.bergmann@smavesto.de
          </a>
          <br />
        </div>
      </div>
      {consultantData && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "left",
            gap: ".5rem",
            maxWidth: 600,
            margin: "auto",
            marginBottom: "1rem",
            paddingBottom: "1rem"
          }}
        >
          <div style={{ textAlign: "center", marginRight: "1rem" }}>
            <Image
              style={{
                borderRadius: "50%",
                objectFit: "cover"
              }}
              alt="contact"
              className="image"
              src={consultantImagePath || "/images/themes/anceka/logo_raw.png"}
              width={100}
              height={100}
            />
          </div>

          <div style={{ maxWidth: 400, fontSize: "16px" }}>
            Kontaktieren Sie Ihren persönlichen {customerSupplier?.name}
            -Ansprechpartner {consultantData.firstName}{" "}
            {consultantData.lastName} über die nachfolgenden Kontaktdaten:
            <br />
            {consultantData.phone && (
              <a
                style={{ textDecoration: "none" }}
                href={`tel:${consultantData.phone}`}
              >
                {consultantData.phone}
              </a>
            )}
            <br />
            <a
              style={{ textDecoration: "none" }}
              href={`mail:${consultantData.email}`}
            >
              {consultantData.email}
            </a>
          </div>
        </div>
      )}
    </BasicInfoBox>
  );
};

export default AnCeKaConsultantHint;
